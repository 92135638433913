import { useEffect, useRef } from "react";
import {
    createChart,
    ColorType,
    AreaData,
    WhitespaceData,
    Time,
} from "lightweight-charts";
const data = [
    { t: 1594339200, v: 1.5881414200138504 },
    { t: 1594425600, v: 1.5813948620968867 },
    { t: 1594512000, v: 1.5873933797269921 },
    { t: 1594598400, v: 1.581664417383889 },
    { t: 1594684800, v: 1.5806794152966626 },
    { t: 1594771200, v: 1.5713329517256016 },
    { t: 1594857600, v: 1.561798450180424 },
    { t: 1594944000, v: 1.5639610567107356 },
    { t: 1595030400, v: 1.565289277663867 },
    { t: 1595116800, v: 1.569569254938576 },
    { t: 1595203200, v: 1.5681460662929108 },
    { t: 1595289600, v: 1.601298834622328 },
    { t: 1595376000, v: 1.6260653305275685 },
    { t: 1595462400, v: 1.6341476122770595 },
    { t: 1595548800, v: 1.6260968659674482 },
    { t: 1595635200, v: 1.649060722239943 },
    { t: 1595721600, v: 1.6869219032530522 },
    { t: 1595808000, v: 1.871052223794051 },
    { t: 1595894400, v: 1.8449549675588206 },
    { t: 1595980800, v: 1.8730472313538216 },
    { t: 1596067200, v: 1.8739266823808407 },
    { t: 1596153600, v: 1.9060424671900422 },
    { t: 1596240000, v: 1.9759933466516988 },
    { t: 1596326400, v: 1.8554178852168537 },
    { t: 1596412800, v: 1.880161856636378 },
    { t: 1596499200, v: 1.871990605020786 },
    { t: 1596585600, v: 1.9581401048733051 },
    { t: 1596672000, v: 1.95527930256849 },
    { t: 1596758400, v: 1.9180124742546403 },
    { t: 1596844800, v: 1.9390567893851103 },
    { t: 1596931200, v: 1.9245083462771742 },
    { t: 1597017600, v: 1.9544455129924663 },
    { t: 1597104000, v: 1.8752816601970614 },
    { t: 1597190400, v: 1.9003751498063595 },
    { t: 1597276800, v: 1.9342889923335342 },
    { t: 1597363200, v: 1.9270170066514205 },
    { t: 1597449600, v: 1.9403282456247257 },
    { t: 1597536000, v: 1.943853272500624 },
    { t: 1597622400, v: 1.9955978379544013 },
    { t: 1597708800, v: 1.9502970654540592 },
    { t: 1597795200, v: 1.9119638164194475 },
    { t: 1597881600, v: 1.9300432764264923 },
    { t: 1597968000, v: 1.8788057784391647 },
    { t: 1598054400, v: 1.8957234121066093 },
    { t: 1598140800, v: 1.8915878072121113 },
    { t: 1598227200, v: 1.9074999208281438 },
    { t: 1598313600, v: 1.8410816172942186 },
    { t: 1598400000, v: 1.8582907353492506 },
    { t: 1598486400, v: 1.8307770336975346 },
    { t: 1598572800, v: 1.8638737213881098 },
    { t: 1598659200, v: 1.859063757824888 },
    { t: 1598745600, v: 1.8899022554739535 },
    { t: 1598832000, v: 1.8828864185840783 },
    { t: 1598918400, v: 1.9244918085163885 },
    { t: 1599004800, v: 1.835106905690946 },
    { t: 1599091200, v: 1.64540674865549 },
    { t: 1599177600, v: 1.690270481693547 },
    { t: 1599264000, v: 1.6360671285924477 },
    { t: 1599350400, v: 1.6540770119758665 },
    { t: 1599436800, v: 1.6693831848077136 },
    { t: 1599523200, v: 1.6314978769453052 },
    { t: 1599609600, v: 1.6495777411734236 },
    { t: 1599696000, v: 1.6688245087587141 },
    { t: 1599782400, v: 1.6751546484947633 },
    { t: 1599868800, v: 1.6819124014513618 },
    { t: 1599955200, v: 1.6626780456363284 },
    { t: 1600041600, v: 1.7194841445020932 },
    { t: 1600128000, v: 1.7366608295080337 },
    { t: 1600214400, v: 1.7630957856759881 },
    { t: 1600300800, v: 1.7579669689753452 },
    { t: 1600387200, v: 1.7558636673567363 },
    { t: 1600473600, v: 1.7782488667467071 },
    { t: 1600560000, v: 1.7526728667983373 },
    { t: 1600646400, v: 1.6768358597336657 },
    { t: 1600732800, v: 1.6876620963952151 },
    { t: 1600819200, v: 1.6378781668290006 },
    { t: 1600905600, v: 1.7214832669695543 },
    { t: 1600992000, v: 1.7133749590541802 },
    { t: 1601078400, v: 1.721087576815284 },
    { t: 1601164800, v: 1.7239703733995473 },
    { t: 1601251200, v: 1.7145113403695502 },
    { t: 1601337600, v: 1.7325390484440242 },
    { t: 1601424000, v: 1.722255332132249 },
    { t: 1601510400, v: 1.6957962856992097 },
    { t: 1601596800, v: 1.688059832519767 },
    { t: 1601683200, v: 1.6836467835095958 },
    { t: 1601769600, v: 1.702329816704673 },
    { t: 1601856000, v: 1.721465906614025 },
    { t: 1601942400, v: 1.6909226819415526 },
    { t: 1602028800, v: 1.700454418799038 },
    { t: 1602115200, v: 1.738540969568825 },
    { t: 1602201600, v: 1.7601064084973481 },
    { t: 1602288000, v: 1.7956412066857375 },
    { t: 1602374400, v: 1.8087501861037292 },
    { t: 1602460800, v: 1.8338279980522612 },
    { t: 1602547200, v: 1.8117626571566943 },
    { t: 1602633600, v: 1.810630175216287 },
    { t: 1602720000, v: 1.8191799894222773 },
    { t: 1602806400, v: 1.7910399106479886 },
    { t: 1602892800, v: 1.7961569391830408 },
    { t: 1602979200, v: 1.8154319185645023 },
    { t: 1603065600, v: 1.8511787720873725 },
    { t: 1603152000, v: 1.8751744222091165 },
    { t: 1603238400, v: 2.0107800474457984 },
    { t: 1603324800, v: 2.021166387936058 },
    { t: 1603411200, v: 2.005132501863364 },
    { t: 1603497600, v: 2.0296000555791207 },
    { t: 1603584000, v: 2.0137769159659817 },
    { t: 1603670400, v: 2.0186308814573484 },
    { t: 1603756800, v: 2.099523978219908 },
    { t: 1603843200, v: 2.038521751971338 },
    { t: 1603929600, v: 2.0608549445546176 },
    { t: 1604016000, v: 2.076110169477788 },
    { t: 1604102400, v: 2.1047890363963337 },
    { t: 1604188800, v: 2.0903488353662576 },
    { t: 1604275200, v: 2.061439644930324 },
    { t: 1604361600, v: 2.0857158396857907 },
    { t: 1604448000, v: 2.1112416014164515 },
    { t: 1604534400, v: 2.3074028495514654 },
    { t: 1604620800, v: 2.2944587848972033 },
    { t: 1604707200, v: 2.1873102726004876 },
    { t: 1604793600, v: 2.2721444787271032 },
    { t: 1604880000, v: 2.243978384778811 },
    { t: 1604966400, v: 2.231692307094705 },
    { t: 1605052800, v: 2.284312828379097 },
    { t: 1605139200, v: 2.360669896391439 },
    { t: 1605225600, v: 2.3569811696020375 },
    { t: 1605312000, v: 2.31880106059858 },
    { t: 1605398400, v: 2.2998420182898873 },
    { t: 1605484800, v: 2.400422464494655 },
    { t: 1605571200, v: 2.522037450221123 },
    { t: 1605657600, v: 2.5166433549027647 },
    { t: 1605744000, v: 2.5064589291084283 },
    { t: 1605830400, v: 2.596816616429715 },
    { t: 1605916800, v: 2.592170658826247 },
    { t: 1606003200, v: 2.5488659343718783 },
    { t: 1606089600, v: 2.531257246929017 },
    { t: 1606176000, v: 2.6142736220732736 },
    { t: 1606262400, v: 2.5616905823206952 },
    { t: 1606348800, v: 2.329049169389932 },
    { t: 1606435200, v: 2.3226891915816683 },
    { t: 1606521600, v: 2.403320415533262 },
    { t: 1606608000, v: 2.457595131040538 },
    { t: 1606694400, v: 2.648300267300762 },
    { t: 1606780800, v: 2.522858126472818 },
    { t: 1606867200, v: 2.5548387760403672 },
    { t: 1606953600, v: 2.5762334938495584 },
    { t: 1607040000, v: 2.4779609110525724 },
    { t: 1607126400, v: 2.5252181219111725 },
    { t: 1607212800, v: 2.5472992578338527 },
    { t: 1607299200, v: 2.5230377514217843 },
    { t: 1607385600, v: 2.4023923446084052 },
    { t: 1607472000, v: 2.42880002154687 },
    { t: 1607558400, v: 2.381750980384586 },
    { t: 1607644800, v: 2.352748788196354 },
    { t: 1607731200, v: 2.4398889919147706 },
    { t: 1607817600, v: 2.4657480099792513 },
    { t: 1607904000, v: 2.4720058983028617 },
    { t: 1607990400, v: 2.4862608353678124 },
    { t: 1608076800, v: 2.7092574496491695 },
    { t: 1608163200, v: 2.845635936264526 },
    { t: 1608249600, v: 2.8339591371510826 },
    { t: 1608336000, v: 2.9138722272531745 },
    { t: 1608422400, v: 2.860264755866448 },
    { t: 1608508800, v: 2.765119462928294 },
    { t: 1608595200, v: 2.8412690613353866 },
    { t: 1608681600, v: 2.755995206658933 },
    { t: 1608768000, v: 2.788354102852438 },
    { t: 1608854400, v: 2.889165130275225 },
    { t: 1608940800, v: 3.075606549123136 },
    { t: 1609027200, v: 3.033761263086829 },
    { t: 1609113600, v: 3.0445621068068607 },
    { t: 1609200000, v: 3.0242828427541673 },
    { t: 1609286400, v: 3.1565359528886687 },
    { t: 1609372800, v: 3.1488733065569052 },
    { t: 1609459200, v: 3.153528213748419 },
    { t: 1609545600, v: 3.382566917855625 },
    { t: 1609632000, v: 3.4180875797162953 },
    { t: 1609718400, v: 3.2639226605058322 },
    { t: 1609804800, v: 3.4322557434885 },
    { t: 1609891200, v: 3.6481833870449947 },
    { t: 1609977600, v: 3.772616585966764 },
    { t: 1610064000, v: 3.8179922682145064 },
    { t: 1610150400, v: 3.717768963876833 },
    { t: 1610236800, v: 3.5110086842968857 },
    { t: 1610323200, v: 3.2321612014018335 },
    { t: 1610409600, v: 3.055097088832567 },
    { t: 1610496000, v: 3.347005336927696 },
    { t: 1610582400, v: 3.4771461018911296 },
    { t: 1610668800, v: 3.2397727123322926 },
    { t: 1610755200, v: 3.1816516524687515 },
    { t: 1610841600, v: 3.135681400600396 },
    { t: 1610928000, v: 3.193578476526415 },
    { t: 1611014400, v: 3.1287829098398117 },
    { t: 1611100800, v: 3.0778735291578956 },
    { t: 1611187200, v: 2.6663926278372214 },
    { t: 1611273600, v: 2.8534016007318623 },
    { t: 1611360000, v: 2.7674687309506942 },
    { t: 1611446400, v: 2.785310551177854 },
    { t: 1611532800, v: 2.785203633237663 },
    { t: 1611619200, v: 2.800500965291001 },
    { t: 1611705600, v: 2.6023748985312176 },
    { t: 1611792000, v: 2.848379132168437 },
    { t: 1611878400, v: 2.89442162870809 },
    { t: 1611964800, v: 2.8821507112767244 },
    { t: 1612051200, v: 2.777127847926129 },
    { t: 1612137600, v: 2.808159943808408 },
    { t: 1612224000, v: 2.9604747967761416 },
    { t: 1612310400, v: 3.107323791915651 },
    { t: 1612396800, v: 3.04322626297914 },
    { t: 1612483200, v: 3.1158461513206626 },
    { t: 1612569600, v: 3.1920795805094553 },
    { t: 1612656000, v: 3.147806038533607 },
    { t: 1612742400, v: 3.6784703862278945 },
    { t: 1612828800, v: 3.6389520514428013 },
    { t: 1612915200, v: 3.4720542488876283 },
    { t: 1613001600, v: 3.6641909375847708 },
    { t: 1613088000, v: 3.604195858071073 },
    { t: 1613174400, v: 3.557137895509002 },
    { t: 1613260800, v: 3.6691871028763328 },
    { t: 1613347200, v: 3.5715562382429344 },
    { t: 1613433600, v: 3.636127338582206 },
    { t: 1613520000, v: 3.8124264049147514 },
    { t: 1613606400, v: 3.7309587718840884 },
    { t: 1613692800, v: 3.952541086607873 },
    { t: 1613779200, v: 3.921978544305177 },
    { t: 1613865600, v: 3.981517188114521 },
    { t: 1613952000, v: 3.717829220897379 },
    { t: 1614038400, v: 3.3048231899325926 },
    { t: 1614124800, v: 3.3516286808641307 },
    { t: 1614211200, v: 3.16574334980615 },
    { t: 1614297600, v: 3.075970910691417 },
    { t: 1614384000, v: 3.0833425320294525 },
    { t: 1614470400, v: 3.0003557663006664 },
    { t: 1614556800, v: 3.294147583645988 },
    { t: 1614643200, v: 3.1874526640546064 },
    { t: 1614729600, v: 3.3249148789545537 },
    { t: 1614816000, v: 3.172335192086677 },
    { t: 1614902400, v: 3.1889172001430266 },
    { t: 1614988800, v: 3.1824592197224493 },
    { t: 1615075200, v: 3.329604045322902 },
    { t: 1615161600, v: 3.3776255931093346 },
    { t: 1615248000, v: 3.51041232202218 },
    { t: 1615334400, v: 3.5781229842160758 },
    { t: 1615420800, v: 3.645206594316009 },
    { t: 1615507200, v: 3.5879467972131587 },
    { t: 1615593600, v: 3.804501410521186 },
    { t: 1615680000, v: 3.6890257214991444 },
    { t: 1615766400, v: 3.4346326874927855 },
    { t: 1615852800, v: 3.4546627253161617 },
    { t: 1615939200, v: 3.5740330032655603 },
    { t: 1616025600, v: 3.5089192706206904 },
    { t: 1616112000, v: 3.527681958745154 },
    { t: 1616198400, v: 3.519957051866139 },
    { t: 1616284800, v: 3.4555409926246 },
    { t: 1616371200, v: 3.267221504448423 },
    { t: 1616457600, v: 3.2645020957367765 },
    { t: 1616544000, v: 3.124741633735043 },
    { t: 1616630400, v: 3.049061332956381 },
    { t: 1616716800, v: 3.240557615645055 },
    { t: 1616803200, v: 3.2793185465843986 },
    { t: 1616889600, v: 3.267951153975118 },
    { t: 1616976000, v: 3.3570281831911544 },
    { t: 1617062400, v: 3.4076217126574013 },
    { t: 1617148800, v: 3.391530521244242 },
    { t: 1617235200, v: 3.379242839114332 },
    { t: 1617321600, v: 3.3803506782524386 },
    { t: 1617408000, v: 3.2762844047769475 },
    { t: 1617494400, v: 3.3335876562912055 },
    { t: 1617580800, v: 3.3330218049146305 },
    { t: 1617667200, v: 3.2416094974805683 },
    { t: 1617753600, v: 3.122223028136836 },
    { t: 1617840000, v: 3.2301963410486825 },
    { t: 1617926400, v: 3.2181519944919676 },
    { t: 1618012800, v: 3.292693698026258 },
    { t: 1618099200, v: 3.3040011900972757 },
    { t: 1618185600, v: 3.27133923386944 },
    { t: 1618272000, v: 3.4480967150554784 },
    { t: 1618358400, v: 3.3821202132520827 },
    { t: 1618444800, v: 3.38088072157034 },
    { t: 1618531200, v: 3.282170031933555 },
    { t: 1618617600, v: 3.230014516511469 },
    { t: 1618704000, v: 2.9504102629799545 },
    { t: 1618790400, v: 2.9220127266650824 },
    { t: 1618876800, v: 2.954444690522329 },
    { t: 1618963200, v: 2.8199120604380115 },
    { t: 1619049600, v: 2.7050936502772758 },
    { t: 1619136000, v: 2.6647195978885296 },
    { t: 1619222400, v: 2.6072477414646427 },
    { t: 1619308800, v: 2.5559045738850554 },
    { t: 1619395200, v: 2.8060530082736546 },
    { t: 1619481600, v: 2.843771048943397 },
    { t: 1619568000, v: 2.828585889300855 },
    { t: 1619654400, v: 2.755208067474532 },
    { t: 1619740800, v: 2.9584934009305126 },
    { t: 1619827200, v: 2.954838589989 },
    { t: 1619913600, v: 2.889141439770834 },
    { t: 1620000000, v: 2.9103536081857095 },
    { t: 1620086400, v: 2.7089117711936153 },
    { t: 1620172800, v: 2.9082708495774345 },
    { t: 1620259200, v: 2.8508890201231734 },
    { t: 1620345600, v: 2.8751068056955327 },
    { t: 1620432000, v: 2.9382436373498813 },
    { t: 1620518400, v: 2.904393112847789 },
    { t: 1620604800, v: 2.7785571418284123 },
    { t: 1620691200, v: 2.8202836764814028 },
    { t: 1620777600, v: 2.471394652018376 },
    { t: 1620864000, v: 2.4639913288523525 },
    { t: 1620950400, v: 2.47044174233462 },
    { t: 1621036800, v: 2.3180587790802862 },
    { t: 1621123200, v: 2.299469130196942 },
    { t: 1621209600, v: 2.155025478705301 },
    { t: 1621296000, v: 2.1370248139600743 },
    { t: 1621382400, v: 1.8600544132558692 },
    { t: 1621468800, v: 2.0359496091910825 },
    { t: 1621555200, v: 1.876673948834968 },
    { t: 1621641600, v: 1.8989849557714333 },
    { t: 1621728000, v: 1.762857567811395 },
    { t: 1621814400, v: 1.9494684573597296 },
    { t: 1621900800, v: 1.929183872477886 },
    { t: 1621987200, v: 1.9809016122810559 },
    { t: 1622073600, v: 1.945217629136084 },
    { t: 1622160000, v: 1.8050367464806465 },
    { t: 1622246400, v: 1.752461783142778 },
    { t: 1622332800, v: 1.8029322594898394 },
    { t: 1622419200, v: 1.8881963412763716 },
    { t: 1622505600, v: 1.852756315051152 },
    { t: 1622592000, v: 1.904181697884718 },
    { t: 1622678400, v: 1.9790920156552396 },
    { t: 1622764800, v: 1.8707156428876868 },
    { t: 1622851200, v: 1.7988963351951879 },
    { t: 1622937600, v: 1.8130673779650504 },
    { t: 1623024000, v: 1.7022254950964255 },
    { t: 1623110400, v: 1.7020038435655844 },
    { t: 1623196800, v: 1.8947534297426472 },
    { t: 1623283200, v: 1.8663826763160394 },
    { t: 1623369600, v: 1.8906121804236968 },
    { t: 1623456000, v: 1.803793269978764 },
    { t: 1623542400, v: 1.981269728702131 },
    { t: 1623628800, v: 2.054224941309015 },
    { t: 1623715200, v: 2.0372156054953705 },
    { t: 1623801600, v: 1.9380047950939376 },
    { t: 1623888000, v: 1.9309583545257394 },
    { t: 1623974400, v: 1.8108349774254622 },
    { t: 1624060800, v: 1.8091880870969472 },
    { t: 1624147200, v: 1.8116994345796444 },
    { t: 1624233600, v: 1.6067197067090802 },
    { t: 1624320000, v: 1.654730951105218 },
    { t: 1624406400, v: 1.7146315843221278 },
    { t: 1624492800, v: 1.7646735310752728 },
    { t: 1624579200, v: 1.6291422522268542 },
    { t: 1624665600, v: 1.6474747121709914 },
    { t: 1624752000, v: 1.781806913490385 },
    { t: 1624838400, v: 1.7711773649730558 },
    { t: 1624924800, v: 1.847346865655404 },
    { t: 1625011200, v: 1.8036646783083894 },
    { t: 1625097600, v: 1.7305169340397892 },
    { t: 1625184000, v: 1.7542173539268198 },
    { t: 1625270400, v: 1.7922245439006925 },
    { t: 1625356800, v: 1.823182165105784 },
    { t: 1625443200, v: 1.7479970364560626 },
    { t: 1625529600, v: 1.765649919186559 },
    { t: 1625616000, v: 1.7493808441533352 },
    { t: 1625702400, v: 1.6993204139534106 },
    { t: 1625788800, v: 1.752446739868496 },
    { t: 1625875200, v: 1.733894547359329 },
    { t: 1625961600, v: 1.7711896041389827 },
    { t: 1626048000, v: 1.7157955075397893 },
    { t: 1626134400, v: 1.6873325727924267 },
    { t: 1626220800, v: 1.6985315169330955 },
    { t: 1626307200, v: 1.6418030688701353 },
    { t: 1626393600, v: 1.630498207057908 },
    { t: 1626480000, v: 1.630391696027207 },
    { t: 1626566400, v: 1.6448158105373643 },
    { t: 1626652800, v: 1.5949910128625668 },
    { t: 1626739200, v: 1.5414843469305992 },
    { t: 1626825600, v: 1.6628577866661227 },
    { t: 1626912000, v: 1.672065712532477 },
    { t: 1626998400, v: 1.7369068983758065 },
    { t: 1627084800, v: 1.7761667103521068 },
    { t: 1627171200, v: 1.8265895672521908 },
    { t: 1627257600, v: 1.92857242706536 },
    { t: 1627344000, v: 2.0137953189107747 },
    { t: 1627430400, v: 2.0399860078698886 },
    { t: 1627516800, v: 2.017084846397771 },
    { t: 1627603200, v: 2.11802366106516 },
    { t: 1627689600, v: 2.096893807890876 },
    { t: 1627776000, v: 2.017763023317999 },
    { t: 1627862400, v: 1.9692705302288425 },
    { t: 1627948800, v: 1.9184475964034136 },
    { t: 1628035200, v: 1.9994549124701073 },
    { t: 1628121600, v: 2.05114263160099 },
    { t: 1628208000, v: 2.1464812428256708 },
    { t: 1628294400, v: 2.237270768367695 },
    { t: 1628380800, v: 2.1900728084735777 },
    { t: 1628467200, v: 2.309228325518527 },
    { t: 1628553600, v: 2.2707100126813695 },
    { t: 1628640000, v: 2.267322908753769 },
    { t: 1628726400, v: 2.207799732611834 },
    { t: 1628812800, v: 2.372498688877108 },
    { t: 1628899200, v: 2.3305361731442047 },
    { t: 1628985600, v: 2.327745507409937 },
    { t: 1629072000, v: 2.2723850542962256 },
    { t: 1629158400, v: 2.2062201919164526 },
    { t: 1629244800, v: 2.208418785220058 },
    { t: 1629331200, v: 2.2997637195474376 },
    { t: 1629417600, v: 2.4236566430894713 },
    { t: 1629504000, v: 2.3989139727873767 },
    { t: 1629590400, v: 2.4168859589033334 },
    { t: 1629676800, v: 2.421149560706229 },
    { t: 1629763200, v: 2.3319947940333448 },
    { t: 1629849600, v: 2.380242590086712 },
    { t: 1629936000, v: 2.279159387795798 },
    { t: 1630022400, v: 2.377775712746794 },
    { t: 1630108800, v: 2.3688292693571356 },
    { t: 1630195200, v: 2.370563694800223 },
    { t: 1630281600, v: 2.278611124179325 },
    { t: 1630368000, v: 2.283080995456492 },
    { t: 1630454400, v: 2.353763836791836 },
    { t: 1630540800, v: 2.3766158660887804 },
    { t: 1630627200, v: 2.4051556839608836 },
    { t: 1630713600, v: 2.399777957358442 },
    { t: 1630800000, v: 2.4837432599378704 },
    { t: 1630886400, v: 2.5215157468651066 },
    { t: 1630972800, v: 2.244139632258 },
    { t: 1631059200, v: 2.2076892861073123 },
    { t: 1631145600, v: 2.2199771620673774 },
    { t: 1631232000, v: 2.148622897736807 },
    { t: 1631318400, v: 2.160541929596506 },
    { t: 1631404800, v: 2.2042608190658664 },
    { t: 1631491200, v: 2.153429235297209 },
    { t: 1631577600, v: 2.2472985657679927 },
    { t: 1631664000, v: 2.2959396061053594 },
    { t: 1631750400, v: 2.2765043210870934 },
    { t: 1631836800, v: 2.2467009402681937 },
    { t: 1631923200, v: 2.293937218559545 },
    { t: 1632009600, v: 2.246636781002067 },
    { t: 1632096000, v: 2.0430548101093713 },
    { t: 1632182400, v: 1.9379242423983336 },
    { t: 1632268800, v: 2.076429840491864 },
    { t: 1632355200, v: 2.1383585435003427 },
    { t: 1632441600, v: 2.0405788098787023 },
    { t: 1632528000, v: 2.038176413350152 },
    { t: 1632614400, v: 2.0481884369425036 },
    { t: 1632700800, v: 1.9998367216374957 },
    { t: 1632787200, v: 1.9439357421305525 },
    { t: 1632873600, v: 1.9676066944863935 },
    { t: 1632960000, v: 2.069654050529713 },
    { t: 1633046400, v: 2.270075393572561 },
    { t: 1633132800, v: 2.249203428880476 },
    { t: 1633219200, v: 2.2693650699758257 },
    { t: 1633305600, v: 2.306643216083629 },
    { t: 1633392000, v: 2.416636054259379 },
    { t: 1633478400, v: 2.57993086919147 },
    { t: 1633564800, v: 2.492031095899531 },
    { t: 1633651200, v: 2.492229522331689 },
    { t: 1633737600, v: 2.543729870956801 },
    { t: 1633824000, v: 2.521320359681032 },
    { t: 1633910400, v: 2.6412680072747334 },
    { t: 1633996800, v: 2.571167273940489 },
    { t: 1634083200, v: 2.625269740973596 },
    { t: 1634169600, v: 2.6160274559867895 },
    { t: 1634256000, v: 2.794404809062617 },
    { t: 1634342400, v: 2.757010879635927 },
    { t: 1634428800, v: 2.787696798550357 },
    { t: 1634515200, v: 2.79191716681121 },
    { t: 1634601600, v: 2.8723995934157096 },
    { t: 1634688000, v: 2.9303958820995146 },
    { t: 1634774400, v: 2.7579944575973454 },
    { t: 1634860800, v: 2.6819465242539025 },
    { t: 1634947200, v: 2.7077229621872667 },
    { t: 1635033600, v: 2.6816421520840943 },
    { t: 1635120000, v: 2.7619191718364537 },
    { t: 1635206400, v: 2.645362105682025 },
    { t: 1635292800, v: 2.5673203282418737 },
    { t: 1635379200, v: 2.6458496795381987 },
    { t: 1635465600, v: 2.711782482654078 },
    { t: 1635552000, v: 2.687477389949965 },
    { t: 1635638400, v: 2.666170447095758 },
    { t: 1635724800, v: 2.6401562529917424 },
    { t: 1635811200, v: 2.715335552796559 },
    { t: 1635897600, v: 2.696967959260708 },
    { t: 1635984000, v: 2.6283845875554936 },
    { t: 1636070400, v: 2.6082669512839307 },
    { t: 1636156800, v: 2.6211128466936815 },
    { t: 1636243200, v: 2.693532341485252 },
    { t: 1636329600, v: 2.861274605735813 },
    { t: 1636416000, v: 2.822474406168366 },
    { t: 1636502400, v: 2.7239663087790777 },
    { t: 1636588800, v: 2.71837817653325 },
    { t: 1636675200, v: 2.6611487606224817 },
    { t: 1636761600, v: 2.666371826122284 },
    { t: 1636848000, v: 2.7005996078556973 },
    { t: 1636934400, v: 2.627165576522465 },
    { t: 1637020800, v: 2.482184261142194 },
    { t: 1637107200, v: 2.4806633335158668 },
    { t: 1637193600, v: 2.34001384322171 },
    { t: 1637280000, v: 2.3915532213537976 },
    { t: 1637366400, v: 2.4571163698387295 },
    { t: 1637452800, v: 2.410503384117357 },
    { t: 1637539200, v: 2.3172679636052727 },
    { t: 1637625600, v: 2.3682997955402523 },
    { t: 1637712000, v: 2.350012915504839 },
    { t: 1637798400, v: 2.404040132275191 },
    { t: 1637884800, v: 2.197183759606922 },
    { t: 1637971200, v: 2.229444587499521 },
    { t: 1638057600, v: 2.33695631391496 },
    { t: 1638144000, v: 2.3573478360108564 },
    { t: 1638230400, v: 2.3186111788879042 },
    { t: 1638316800, v: 2.3253724785053773 },
    { t: 1638403200, v: 2.2955159859069822 },
    { t: 1638489600, v: 2.1877380364832826 },
    { t: 1638576000, v: 2.0016179503810205 },
    { t: 1638662400, v: 2.011132172753008 },
    { t: 1638748800, v: 2.067590458403849 },
    { t: 1638835200, v: 2.0656327567039687 },
    { t: 1638921600, v: 2.063662329945575 },
    { t: 1639008000, v: 1.9593242439545626 },
    { t: 1639094400, v: 1.933753237071466 },
    { t: 1639180800, v: 2.0185215012376765 },
    { t: 1639267200, v: 2.0493884455513687 },
    { t: 1639353600, v: 1.9135818876258128 },
    { t: 1639440000, v: 1.9803705683053254 },
    { t: 1639526400, v: 1.9968319916246358 },
    { t: 1639612800, v: 1.950429983577657 },
    { t: 1639699200, v: 1.8962770219877465 },
    { t: 1639785600, v: 1.9224304395918963 },
    { t: 1639872000, v: 1.9152697214439196 },
    { t: 1639958400, v: 1.9193760536634727 },
    { t: 1640044800, v: 2.0036752732165524 },
    { t: 1640131200, v: 1.990052663012192 },
    { t: 1640217600, v: 2.0752930279878306 },
    { t: 1640304000, v: 2.077150875743283 },
    { t: 1640390400, v: 2.0684806275305867 },
    { t: 1640476800, v: 2.0746163347066697 },
    { t: 1640563200, v: 2.064790775993561 },
    { t: 1640649600, v: 1.9464493542317922 },
    { t: 1640736000, v: 1.8962586677500717 },
    { t: 1640822400, v: 1.9279804808901546 },
    { t: 1640908800, v: 1.8925502286489324 },
    { t: 1640995200, v: 1.9449057659694804 },
    { t: 1641081600, v: 1.933677725904818 },
    { t: 1641168000, v: 1.8986794661441404 },
    { t: 1641254400, v: 1.8776525036050642 },
    { t: 1641340800, v: 1.7826901570724525 },
    { t: 1641427200, v: 1.7681897883982771 },
    { t: 1641513600, v: 1.7062891665706514 },
    { t: 1641600000, v: 1.7144490214742034 },
    { t: 1641686400, v: 1.7215874607998833 },
    { t: 1641772800, v: 1.7189836633114786 },
    { t: 1641859200, v: 1.7566239000774888 },
    { t: 1641945600, v: 1.8053142445001482 },
    { t: 1642032000, v: 1.7509344760346799 },
    { t: 1642118400, v: 1.7720636103874885 },
    { t: 1642204800, v: 1.775804176179446 },
    { t: 1642291200, v: 1.7725627362218659 },
    { t: 1642377600, v: 1.7376943416655375 },
    { t: 1642464000, v: 1.7443249012830022 },
    { t: 1642550400, v: 1.7201092392311719 },
    { t: 1642636800, v: 1.6785558270776086 },
    { t: 1642723200, v: 1.5108210376221958 },
    { t: 1642809600, v: 1.4557256996039731 },
    { t: 1642896000, v: 1.5068913196285683 },
    { t: 1642982400, v: 1.526601052675884 },
    { t: 1643068800, v: 1.5388396235071247 },
    { t: 1643155200, v: 1.5353688662527283 },
    { t: 1643241600, v: 1.5494457651091362 },
    { t: 1643328000, v: 1.5781219448169732 },
    { t: 1643414400, v: 1.5953836479864723 },
    { t: 1643500800, v: 1.5882998584762567 },
    { t: 1643587200, v: 1.6107024727832724 },
    { t: 1643673600, v: 1.6156531799781229 },
    { t: 1643760000, v: 1.5391055234553452 },
    { t: 1643846400, v: 1.5441731244598207 },
    { t: 1643932800, v: 1.72945885462475 },
    { t: 1644019200, v: 1.7241164394877504 },
    { t: 1644105600, v: 1.7650961727457748 },
    { t: 1644192000, v: 1.8248100310308957 },
    { t: 1644278400, v: 1.8311448236495105 },
    { t: 1644364800, v: 1.8398372522904558 },
    { t: 1644451200, v: 1.8048160482683182 },
    { t: 1644537600, v: 1.7570255891184077 },
    { t: 1644624000, v: 1.7477275285040366 },
    { t: 1644710400, v: 1.749328622917727 },
    { t: 1644796800, v: 1.764379401590843 },
    { t: 1644883200, v: 1.845015425829907 },
    { t: 1644969600, v: 1.818591813162076 },
    { t: 1645056000, v: 1.677812153780083 },
    { t: 1645142400, v: 1.6577679669717333 },
    { t: 1645228800, v: 1.6619569625247563 },
    { t: 1645315200, v: 1.5939944845626193 },
    { t: 1645401600, v: 1.5349948499045503 },
    { t: 1645488000, v: 1.5900481223802727 },
    { t: 1645574400, v: 1.5477417658293942 },
    { t: 1645660800, v: 1.5914330696918395 },
    { t: 1645747200, v: 1.6294816807464088 },
    { t: 1645833600, v: 1.6227142684523144 },
    { t: 1645920000, v: 1.5675803273884013 },
    { t: 1646006400, v: 1.7912736803690252 },
    { t: 1646092800, v: 1.8362680103795033 },
    { t: 1646179200, v: 1.8180023657847018 },
    { t: 1646265600, v: 1.7554305568613182 },
    { t: 1646352000, v: 1.620225383364982 },
    { t: 1646438400, v: 1.6317413741212685 },
    { t: 1646524800, v: 1.5908272488886144 },
    { t: 1646611200, v: 1.574606905155153 },
    { t: 1646697600, v: 1.6037454326565639 },
    { t: 1646784000, v: 1.7370806211268663 },
    { t: 1646870400, v: 1.634782629076932 },
    { t: 1646956800, v: 1.6067147645563882 },
    { t: 1647043200, v: 1.611612838997151 },
    { t: 1647129600, v: 1.5662960514287105 },
    { t: 1647216000, v: 1.640060310874998 },
    { t: 1647302400, v: 1.6283119248883207 },
    { t: 1647388800, v: 1.7020586146002261 },
    { t: 1647475200, v: 1.694653751081982 },
    { t: 1647561600, v: 1.7299113870222271 },
    { t: 1647648000, v: 1.7445427318649132 },
    { t: 1647734400, v: 1.7053662326102998 },
    { t: 1647820800, v: 1.6985011547446385 },
    { t: 1647907200, v: 1.74926929618976 },
    { t: 1647993600, v: 1.7708980535015295 },
    { t: 1648080000, v: 1.8127805008117897 },
    { t: 1648166400, v: 1.8211281447725292 },
    { t: 1648252800, v: 1.8263502017260977 },
    { t: 1648339200, v: 1.9191809954791996 },
    { t: 1648425600, v: 1.922443714229529 },
    { t: 1648512000, v: 1.9359137905474542 },
    { t: 1648598400, v: 1.9187091633771889 },
    { t: 1648684800, v: 1.8561116392287194 },
    { t: 1648771200, v: 1.8831188002821655 },
    { t: 1648857600, v: 1.8678382966361027 },
    { t: 1648944000, v: 1.8895255074176598 },
    { t: 1649030400, v: 1.895872861992948 },
    { t: 1649116800, v: 1.8530390755563095 },
    { t: 1649203200, v: 1.7581055716133842 },
    { t: 1649289600, v: 1.7723212777879997 },
    { t: 1649376000, v: 1.723186882731453 },
    { t: 1649462400, v: 1.74234652657693 },
    { t: 1649548800, v: 1.7217644534975165 },
    { t: 1649635200, v: 1.6116723177908485 },
    { t: 1649721600, v: 1.6371661272211326 },
];
const Chart = () => {
    const colors = {
        backgroundColor: "white",
        lineColor: "#2962FF",
        textColor: "black",
        areaTopColor: "#2962FF",
        areaBottomColor: "rgba(41, 98, 255, 0.28)",
    };

    const chartContainerRef = useRef<HTMLDivElement>(null);

    type dataType = {
        t: number;
        v: number;
    };
    function fixData(data: dataType[]): (AreaData | WhitespaceData)[] {
        let newData = data.map((d) => {
            return { time: d.t as Time, value: d.v };
        });
        return newData;
    }

    useEffect(() => {
        if (chartContainerRef.current) {
            const container = window.document.querySelector(
                ".chartContainer"
            ) as HTMLDivElement;
            const chart = createChart(container, {
                layout: {
                    background: {
                        type: ColorType.Solid,
                        color: colors.backgroundColor,
                    },
                    textColor: colors.textColor,
                },
                width: container.clientWidth - 10,
                height: 300,
            });

            const handleResize = () => {
                console.log("asdasd");
                chart.applyOptions({
                    width: container.clientWidth - 10,
                });
            };

            chart.timeScale().fitContent();

            const newSeries = chart.addAreaSeries({
                lineColor: colors.lineColor,
                topColor: colors.areaTopColor,
                bottomColor: colors.areaBottomColor,
            });

            const ddd = fixData(data);
            newSeries.setData(ddd);

            container.addEventListener("resize", handleResize);

            return () => {
                if (chartContainerRef.current) {
                    chartContainerRef.current.removeEventListener(
                        "resize",
                        handleResize
                    );
                    chart.remove();
                }
            };
        }
    }, [
        data,
        colors.backgroundColor,
        colors.lineColor,
        colors.textColor,
        colors.areaTopColor,
        colors.areaBottomColor,
    ]);

    return (
        <div
            className="chartContainer"
            ref={chartContainerRef}
            style={{ border: "3px solid red", zIndex: -999 }}
        />
    );
};

// write a function that

export default Chart;
