import { memo } from "react";
import DeleteWidget from "../DeleteWidget";
const TwitterWidget = memo(function ({ id }: { id: string }) {
    return (
        <div className="widget TickerWidget">
            <div className="header">
                <div className="widgetTitle">{`Twitter`}</div>
                <DeleteWidget id={id} />
            </div>
            <div className="content">Twitter Widget...</div>
        </div>
    );
});
export default TwitterWidget;
