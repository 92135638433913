import { memo } from "react";
import DeleteWidget from "../DeleteWidget";
import Chart from "../../../Chart/Chart";
const MVRVWidget = memo(function ({
    id,
    theme,
}: {
    id: string;
    theme: string;
}) {
    return (
        <div className="widget MVRVWidget">
            <div className="header">
                <div className="widgetTitle">Bitcoin - MVRV</div>
                <DeleteWidget id={id} />
            </div>
            <div className="content">
                MVRV WIDGET
                <Chart />
            </div>
        </div>
    );
});
export default MVRVWidget;
